<template>
  <div class="infrastructure-places">
    <div class="infrastructure-places__title">Выберите, что хотите увидеть на карте</div>
    <div class="infrastructure-places__list">
      <template
        v-for="(point, idx) in sectionsList"
        :key="`infrastructure-places-point-${idx}`"
      >
        <div

          class="infrastructure-places__pointWrapper"
          :class="{'__active': _visiblePoint(point)}"
          @click="onChangeActiveCat( idx)"
        >
          <div class="infrastructure-places__icon">
            <img
              alt="point"
              :src="String(point.icon)"
            />
          </div>
          <div class="infrastructure-places__label">{{ point.label }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { InfrastructureBlockItemType } from '~/types/InfrastructureBlockTypes';

const props = defineProps<{
	sectionsList: InfrastructureBlockItemType[];
}>();

const emit = defineEmits<{
	onChangeActiveCategory: [{ index: number }];
}>();

const onChangeActiveCat = (index: number) => {
  emit('onChangeActiveCategory', { index });
};
const _visiblePoint = (point: InfrastructureBlockItemType) => {
  const activePointsList = [...props.sectionsList].filter((t) => t.isHidden);
  if (!activePointsList.length) {
    return true;
  }

  return Boolean(point.isHidden || point.type === 'home');
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.infrastructure-places__title {
	font-size: 21px;
	font-style: normal;
	font-weight: 600;
	line-height: 118%;
	color: var(--primary);
	letter-spacing: -0.96px;
}

.infrastructure-places__list {
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	& > * {
		display: flex;
		align-items: center;
		margin-top: 20px;
		font-size: 16px;
		line-height: 26px;
		color: var(--primary);
	}
}

.infrastructure-places__pointWrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
	opacity: .6;

	&.__active {
		opacity: 1;
	}
}

.infrastructure-places__icon {
	width: 32px;
	height: 32px;
	min-width: 32px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--primary);
	margin-right: 16px;

	& > img {
		width: 16px;
		height: 16px;
		object-fit: cover;

	}
}

.infrastructure-places__label {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	color: var(--primary);
}

@include media('sm') {
	.infrastructure-places__title {
		display: none;
	}

	.infrastructure-places__pointWrapper {
		margin-top: 20px;
	}

	.infrastructure-places__list {
		margin-top: 0;

		& > div:first-child {
			margin-top: 0;
		}

		& > div {
			width: auto;
		}
	}
}
</style>
